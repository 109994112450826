<template>
  <form-wizard
    id="user-report-wizard"
    @on-change="changeTab"
    shape="tab"
    color="#6c757d"
  >
    <template #title>
      &nbsp;
    </template>
    <template slot="footer" slot-scope="props">
      <hr>
      <div class="wizard-footer-left">
        <wizard-button
          v-if="props.activeTabIndex > 1 && !props.isLastStep"
          class="btn btn-primary"
          @click.native="props.prevTab();goBackToSearch()"
        >
          Indietro
        </wizard-button>
      </div>
      <div class="wizard-footer-right">
        <b-button
          variant="danger"
          @click="abortReport"
          class="wizard-btn me-2"
        >
          Chiudi
        </b-button>
        <wizard-button
          v-if="!props.isLastStep && props.activeTabIndex ===0"
          @click.native="props.nextTab()"
          class="btn btn-warning"
        >
          Procedi
        </wizard-button>
        <wizard-button
          v-if="!props.isLastStep && props.activeTabIndex ===1"
          @click.native="props.nextTab()"
          :class="nextButtonComputedClasses"
          class="btn btn-warning"
        >
          Ricerca
        </wizard-button>
        <wizard-button
          v-if="props.isLastStep"
          @click.native="onRequestReport"
          class="btn btn-warning"
        >
          Richiedi Report
        </wizard-button>
        <wizard-button
          v-if="props.isLastStep && !completed"
          @click.native="onRegisterNew"
          class="btn btn-success ms-2"
        >
          Registra la tua azienda
        </wizard-button>
        <wizard-button
          v-if="props.isLastStep && completed"
          @click.native="onComplete"
          class="btn btn-success ms-2"
        >
          Visualizza profilo CRP
        </wizard-button>
      </div>
    </template>
    <tab-content
      title="Avviso"
      icon="fas fa-hand-paper"
      class="content-invest"
    >
      <p>
        Il Portale delle Imprese è il motore di ricerca di Centrale Rischi Pagamenti dedicato alle
        aziende con sede legale in Italia.
      </p>
      <p>
        Puoi effettuare ricerche per ragione sociale o per codice fiscale.
      </p>
      <p>
        Potrai richiedere un Report CRP o altre tipologie di analisi per qualsiasi impresa.
        <br>
        Inoltre, se in CRPagamenti risultano segnalazioni o feedback a carico dell’impresa,
        potrai visualizzarne l’elenco.
      </p>
      <p>
        Se sei il titolare di un’impresa, iscrivila al Portale delle Imprese cliccando su
        Registra la tua azienda. L’iscrizione al Portale consente a un’impresa di effettuare
        feedback e segnalazioni nell’ambito dei propri rapporti contrattuali e professionali, e
        rispondere altresì alle segnalazioni e ai feedback ricevuti, incrementando così il
        proprio scoring e migliorando la valutazione del merito creditizio nei confronti di banche
        ed altre imprese.
      </p>
      <p>
        Per continuare con la ricerca, clicca su Procedi.
      </p>
    </tab-content>
    <tab-content
      title="Ricerca"
      icon="fas fa-binoculars"
      lazy
      :before-change="validateSubject"
    >
      <div>
        <h5 class="text-center mb-4">
          <span>Ricerca nel Portale delle Imprese</span>
        </h5>
        <b-row>
          <b-col md="8" lg="6" offset-md="2" offset-lg="3">
            <div class="mb-3">
              <v-select
                class="vs--wizard"
                placeholder="Inserire la ragione sociale oppure il codice fiscale"
                :options="options"
                @search="onSearch"
                v-model="currentSelection"
              >
                <div slot="no-options">Nessuna opzione disponibile</div>
              </v-select>
            </div>
          </b-col>
        </b-row>
      </div>
    </tab-content>
    <tab-content
      title="Risultato"
      icon="fas fa-file"
    >
      <b-row v-if="isSearch">
        <b-col md="8" offset-md="2" lg="6" offset-lg="3">
          <loader-info />
        </b-col>
      </b-row>
      <div v-else-if="!hasResult">
        <p>
          L’impresa cercata non è censita in CRPagamenti.
        </p>
        <p>
          Ad ogni modo, puoi richiedere ugualmente un Report CRP, contenente le informazioni
          raccolte da CRPagamenti attraverso una pluralità di fonti, sia camerali sia extra-camerali,
          nonché lo Scoring CRP dell’impresa.
        </p>
        <p>
          Se sei il titolare di questa azienda, iscrivila in Centrale Rischi Pagamenti tramite il link sottostante.
        </p>
      </div>
      <div v-else-if="hasResult" class="my-5">
        <b-table-simple striped responsive>
          <b-thead>
            <b-tr>
              <b-th class="white-space-nowrap">Ragione Sociale</b-th>
              <b-th class="white-space-nowrap">Codice Fiscale</b-th>
              <b-th class="white-space-nowrap">Portale delle imprese</b-th>
              <b-th class="white-space-nowrap">Numero di segnalazioni e feedback</b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr>
              <b-td>{{companyName}}</b-td>
              <b-td>{{companyVat}}</b-td>
              <b-td>{{companyStatus}}</b-td>
              <b-td>{{companyItems}}</b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </div>
    </tab-content>
  </form-wizard>
</template>

<script>
import { debounce } from 'lodash';
import { searchCompany } from '@/utils/selectSearch';
import { toastInformation } from '@/utils/toast';
import { isNotEmpty } from '../../utils/validators';

const LoaderInfo = () => import('@/components/helpers/LoaderInfo.vue');

export default {
  name: 'UserClubCompanyWizard',
  components: { LoaderInfo },
  props: {
    id: String,
    requestType: String,
  },
  data() {
    return {
      formReport: {
        company: {
          companyName: '',
          vatNumber: '',
        },
      },
      submitted: false,
      completed: false,
      hasError: false,
      hasTypeChosen: false,
      options: [],
      currentSelection: null,
      addNewSubject: false,
      entityType: 'COMPANY',
      entityId: '',
      companyName: '',
      companyVat: '',
      companyStatus: '',
      companyItems: '',
    };
  },
  mounted() {
    if (!this.isOperative) this.$router.replace('/');
    this.formReport = {
      company: {
        companyName: '',
        vatNumber: '',
      },
    };
    this.submitted = false;
    this.completed = false;
    this.hasError = false;
    this.hasTypeChosen = false;
    this.userChosenType = 'COMPANY';
    this.options = [];
    this.currentSelection = null;
    this.addNewSubject = false;
  },
  computed: {
    isCompanyProfile() {
      const currentProfile = this.$store.getters['subject/currentCompany'];
      return isNotEmpty(currentProfile);
    },
    activityStatus() {
      if (this.isCompanyProfile) {
        return this.$store.getters['subject/activityCompanyStatus'];
      }
      return this.$store.getters['subject/activityPersonStatus'];
    },
    isOperative() {
      return this.activityStatus.operative;
    },
    hasNoResultFound() {
      return false;
    },
    nextButtonComputedClasses() {
      if (this.currentSelection === '') {
        return 'disabled';
      }
      return '';
    },
    hasResult() {
      // check result ricerca
      return this.completed;
    },
    isSearch() {
      return this.$store.getters['score/isLoadingClub'];
    },
  },
  methods: {
    onComplete() {
      this.$router.replace(`/crp-profile/${this.entityType}/${this.companyVat}`);
    },
    abortReport() {
      this.$confirm(
        {
          message: 'Sei sicuro di voler uscire dalla ricerca?',
          button: {
            no: 'No',
            yes: 'Si',
          },
          /**
           * Callback Function
           * @param {Boolean} confirm
           */
          callback: (confirm) => {
            if (confirm) {
              this.$router.replace('/dashboard-utente');
            }
          },
        },
      );
    },
    goBackToSearch() {
      this.currentSelection = '';
    },
    validateSubject() {
      if (this.currentSelection === '') return false;
      return true;
    },
    changeTab(oldIndex, newIndex) {
      // console.log('newIndex', newIndex, this.currentSelection.company.info.vatNumber);
      if (newIndex === 2) {
        const reporterType = 'COMPANY';

        const reportRequest = {
          entityType: reporterType,
          taxCode: this.currentSelection.company.info.vatNumber,
        };

        this.completed = false;
        this.submitted = true;
        this.$store.dispatch('score/searchClub', reportRequest)
          .then((clubSubject) => {
            this.clubSubject = clubSubject.data;
            this.completed = true;
            this.submitted = false;
            console.log('this.reportCreated', this.clubSubject);
            this.companyName = this.clubSubject.name;
            this.companyVat = this.clubSubject.taxCode;
            this.companyStatus = this.clubSubject.clubActive ? 'CENSITA' : 'NON CENSITA';
            this.companyItems = this.clubSubject.reportFeedback;
            this.entityType = this.clubSubject.entityType;
            this.entityId = this.clubSubject.entityId;
          },
          (error) => {
            console.error('Errore in ricerca club virtuosi', error);
            this.completed = false;
            this.submitted = false;
          });
      }
    },
    currentTaxCodeChange(e) {
      // console.log('taxt change', e, e.target.value);
      this.currentSelection = e.target.value;
    },
    onRequestReport() {
      toastInformation('Invio richiesta report');
    },
    onRegisterNew() {
      toastInformation('Invio registra nuovo');
    },
    onSearch(search, loading) {
      console.log('searching?');
      if (search.length) {
        console.log('should be searching');
        loading(true);
        this.search(loading, search, this);
      }
    },
    search: debounce((loading, search, vm) => {
      console.log('inside search');
      let request = null;
      request = searchCompany(search);
      if (!request) {
        // eslint-disable-next-line no-param-reassign
        vm.options = [];
        return;
      }
      request.then((res) => {
        console.log('res', res);
        // eslint-disable-next-line no-param-reassign
        vm.options = res;
        loading(false);
      }).catch((err) => {
        console.log('error search ', vm.userChosenType, err);
        loading(false);
      });
    }, 350),
  },
};
</script>

<style scoped>

</style>
